[data-target="tooltip"][data-popper-placement^="top"] > [data-popper-arrow] {
  bottom: -4px;
}

[data-target="tooltip"][data-popper-placement^="bottom"] > [data-popper-arrow] {
  top: -4px;
}

[data-target="tooltip"][data-popper-placement^="left"] > [data-popper-arrow] {
  right: -0px;
}

[data-target="tooltip"][data-popper-placement^="right"] > [data-popper-arrow] {
  left: -4px;
}
